<template>
  <div class="patient-information-form-wrapper">
    <div class="form">
      <div class="patient-information-part">
        <h5>Supplemental Documents</h5>
        <hr class="bold-hr" />
        <div>
          <div class="full-width-table-container">
            <table>
              <tbody>
                <tr v-for="(supportingDoc, index) in supplementalDocs" :key="index">
                  <td class="input-wrapper">
                    <span v-if="supportingDoc.links.length">
                      <div v-for="link in supportingDoc.links" :key="link" class="input-wrapper">
                        <div class="download">
                          <a :href="link" target="_blank">Download</a>
                        </div>
                      </div>
                    </span>
                    <span v-else>
                      <UploadFileComponent
                        :download-destination="props.downloadDestination"
                        :download-destination-index="index"
                        class="input-wrapper"
                        @download-link="addFreeFormSupplementalDocsDownloadLink" />
                    </span>
                  </td>
                  <td class="input-wrapper">
                    <div>
                      <input
                        :id="`supporting-doc-label-${index}`"
                        v-model="supportingDoc.label"
                        placeholder="Add label to your upload"
                        type="text" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="in-form-button-wrapper">
          <button @click="addSupportingDocumentUpload">+ Add Supplemental Document</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import UploadFileComponent from "@/components/UploadFileComponent";

const emit = defineEmits(["add-free-form-supplemental-docs"]);

const props = defineProps({
  supplementalDocs: {
    type: Array,
    required: true,
  },
  downloadDestination: {
    type: String,
    default() {
      return "";
    },
  },
});

const supplementalDocs = ref(props.supplementalDocs);

function addSupportingDocumentUpload() {
  supplementalDocs.value.push({
    label: null,
    links: [],
  });
}

const addFreeFormSupplementalDocsDownloadLink = (downloadLinkInfo) => {
  emit("add-free-form-supplemental-docs", downloadLinkInfo);
};
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
