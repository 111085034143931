<template>
  <div class="patient-information-form-wrapper">
    <div class="patient-information-page-title">
      <h1>Do Prior Auth</h1>
      <h1>Attach Supporting Documents</h1>
      <p>Suggested Supporting Documents are Customized Based on Your Patients Profile.</p>
    </div>
    <!-- <div class="full-width-table-container">
      <table>
        <thead>
          <tr>
            <th>Selected Diagnoses</th>
            <th>ICD-10 Codes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-if="orderInfo.primaryDiagnosis.diagnosis && orderInfo.primaryDiagnosis.diagnosis.length">
              {{ orderInfo.primaryDiagnosis.diagnosis }}
            </td>
            <td v-if="orderInfo.primaryDiagnosis.icd10Code && orderInfo.primaryDiagnosis.icd10Code.length">
              {{ orderInfo.primaryDiagnosis.icd10Code }}
            </td>
          </tr>
          <tr v-for="diagnosis in orderInfo.otherDiagnosis" :key="diagnosis.diagnosis">
            <td v-if="diagnosis.diagnosis && diagnosis.diagnosis.length">{{ diagnosis.diagnosis }}</td>
            <td v-if="diagnosis.icd10Code && diagnosis.icd10Code.length">{{ diagnosis.icd10Code }}</td>
          </tr>
        </tbody>
      </table>
    </div> -->
    <div class="form">
      <div v-if="orderInfo.labResults && orderInfo.labResults.length" id="labResults" class="patient-information-part">
        <h5>Supporting Documents - Clinical Lab Results</h5>
        <hr class="bold-hr" />
        <div>
          <div class="full-width-table-container">
            <table>
              <thead>
                <tr>
                  <th>Clinical</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="labResult in orderInfo.labResults" :key="labResult.label">
                  <td>{{ labResult.label }}</td>
                  <td>
                    <span v-if="labResult.links.length">
                      <div v-for="address in labResult.links" :key="address" class="input-wrapper">
                        <div class="download">
                          <a :href="address" target="_blank">Download</a>
                        </div>
                      </div>
                    </span>
                    <UploadFileComponent
                      :download-destination="`labResults`"
                      :download-label="labResult.label"
                      class="input-wrapper"
                      @download-link="addDownloadLink" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <span v-if="!isLabResultsValid && nextButtonClicked" class="input-error-notification">
            Missing clinicals: <br />
            <span v-for="labResult in labResultsMissing" :key="labResult"> {{ labResult }} <br /> </span>
            Chances of denial are higher if not added to Prior Authorization request.
          </span>
        </div>
      </div>

      <div
        v-if="orderInfo.patientChart && orderInfo.patientChart.length"
        id="patientChartNotes"
        class="patient-information-part">
        <h5>Supporting Documents - Patient Chart History</h5>
        <hr class="bold-hr" />
        <div>
          <p>Payer policies look for inclusion of the following:</p>
          <div class="full-width-table-container">
            <table>
              <thead>
                <tr>
                  <th>Patient History</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="chartNote in orderInfo.patientChart" :key="chartNote.label">
                  <td>{{ chartNote.label }}</td>
                  <td>
                    <span v-if="chartNote.links.length">
                      <div v-for="address in chartNote.links" :key="address" class="input-wrapper">
                        <div class="download">
                          <a :href="address" target="_blank">Download</a>
                        </div>
                      </div>
                    </span>
                    <UploadFileComponent
                      :download-destination="`patientChart`"
                      :download-label="chartNote.label"
                      class="input-wrapper"
                      @download-link="addDownloadLink" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <span v-if="!isPatientChartNotesValid && nextButtonClicked" class="input-error-notification">
            Missing patient chart notes: <br />
            <span v-for="note in patientChartNotesMissing" :key="note"> {{ note }} <br /> </span>
            Chances of denial are higher if not added to Prior Authorization request.
          </span>
        </div>
      </div>

      <SupplementalDocumentsUploadComponent
        :supplemental-docs="supplementalDocs"
        @add-free-form-supplemental-docs="addFreeFormSupplementalDocs" />

      <div id="medicalNecessity" class="patient-information-part">
        <h5>Statement of Medical Necessity</h5>
        <hr class="bold-hr" />
        <div v-if="orderInfo.medicalNecessityTemplate && orderInfo.medicalNecessityTemplate.length">
          <div class="accented-paragraph">Download Template to Get Signed By Physician</div>
          <div class="download-template">
            <a :href="orderInfo.medicalNecessityTemplate" target="_blank">Download Template</a>
          </div>
        </div>

        <div>
          <div v-if="orderInfo.medicalNecessity" class="reverse-td-width">
            <div class="full-width-table-container">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span><p class="label">Statement of Medical Necessity</p></span>
                    </td>
                    <td>
                      <div v-for="statement in orderInfo.medicalNecessity" :key="statement" class="input-wrapper">
                        <div class="download">
                          <a :href="statement" target="_blank">Download</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else class="reverse-td-width">
            <div class="full-width-table-container">
              <table>
                <tbody>
                  <tr>
                    <td class="input-wrapper">
                      <span><p class="label">Upload Statement Signed by Physician</p></span>
                    </td>
                    <td>
                      <UploadFileComponent
                        :download-destination="`medicalNecessity`"
                        class="input-wrapper"
                        @download-link="addDownloadLink" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <span v-if="!isMedicalNecessityValid && nextButtonClicked" class="input-error-notification">
            Missing statement of medical necessity. Chances of denial are extremely high if not added to Prior
            Authorization request.
          </span>
        </div>
      </div>
    </div>
    <div class="form">
      <div class="guidelines-checklist">
        <h5 v-if="orderInfo.guidelines.length">
          I want to attach the following clinical guidelines to my patient’s prior authorization
        </h5>
        <div v-for="guideline in orderInfo.guidelines" :key="guideline.label" class="checklist-item">
          <input :id="`${guideline.label}`" v-model="selectedGuidelines" :value="guideline" type="checkbox" />
          <label :for="`${guideline.label}`">
            <a :href="guideline.url" target="_blank">{{ guideline.label }}</a>
          </label>
        </div>
      </div>
    </div>

    <div class="form">
      <div class="buttons-wrapper">
        <button
          class="return-button"
          :class="{ 'button-clicked': draftSavingInProgress }"
          :disabled="draftSavingInProgress"
          @click="savePartialOrderInfo">
          Save Draft
        </button>
        <button class="next-button" :disabled="draftSavingInProgress" @click="handleNextClicked">Next</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useOrderInfoStore } from "@/stores/orderInfoStore";
import { orderInfoService } from "@/services/orderInfoService";
import UploadFileComponent from "@/components/UploadFileComponent";
import SupplementalDocumentsUploadComponent from "@/components/SupplementalDocumentsUploadComponent";

const { orderInfo } = storeToRefs(useOrderInfoStore());
const draftSavingInProgress = ref(false);
const supplementalDocs = ref([]);

onMounted(() => {
  orderInfo.value.formStep = "Supporting Documents";
  if (
    (!orderInfo.value.patientChart && !orderInfo.value.labResults) ||
    (orderInfo.value.patientChart &&
      orderInfo.value.patientChart.length === 0 &&
      orderInfo.value.labResults &&
      orderInfo.value.labResults.length === 0)
  ) {
    supplementalDocs.value.push({ label: null, links: [] });
  }
  supplementalDocs.value.push({ label: null, links: [] });
});

const nextButtonClicked = ref(false);

const isLabResultsValid = computed(() => {
  if (orderInfo.value.labResults && orderInfo.value.labResults.length) {
    return orderInfo.value.labResults.some((labResult) => Array.isArray(labResult.links) && labResult.links.length > 0);
  } else {
    return true;
  }
});

const isPatientChartNotesValid = computed(() => {
  if (orderInfo.value.patientChart && orderInfo.value.patientChart.length) {
    return orderInfo.value.patientChart.some(
      (chartNote) => Array.isArray(chartNote.links) && chartNote.links.length > 0
    );
  } else {
    return true;
  }
});

const isMedicalNecessityValid = computed(() => {
  if (orderInfo.value.medicalNecessityTemplate && orderInfo.value.medicalNecessityTemplate.length) {
    return orderInfo.value.medicalNecessity;
  } else {
    return true;
  }
});

const labResultsMissing = computed(() => {
  return orderInfo.value.labResults
    .filter((labResult) => Array.isArray(labResult.links) && labResult.links.length === 0)
    .map((labResult) => labResult.label);
});

const patientChartNotesMissing = computed(() => {
  return orderInfo.value.patientChart
    .filter((patientChartNote) => Array.isArray(patientChartNote.links) && patientChartNote.links.length === 0)
    .map((patientChartNote) => patientChartNote.label);
});

const selectedGuidelines = ref([...orderInfo.value.guidelines]);

const emit = defineEmits(["nextClicked"]);

function scrollToSection(id) {
  const idView = document.getElementById(`${id}`);
  if (!idView) return;
  setTimeout(() => {
    idView.scrollIntoView({ block: "start", behavior: "smooth" });
  }, 0);
  return;
}

async function savePartialOrderInfo() {
  draftSavingInProgress.value = true;
  if (supplementalDocs.value.length > 0 && supplementalDocs.value[0].links.length > 0) {
    if (orderInfo.value.patientChart && orderInfo.value.patientChart.length) {
      orderInfo.value.patientChart.push(...supplementalDocs.value);
    } else {
      orderInfo.value.patientChart = supplementalDocs.value;
    }
    orderInfo.value.patientChart = orderInfo.value.patientChart.filter(
      (v, i, a) => a.findIndex((t) => t.label === v.label) === i
    );
  }
  await orderInfoService.saveOrder(orderInfo.value.orderKey, orderInfo.value);
  draftSavingInProgress.value = false;
}

const handleNextClicked = () => {
  nextButtonClicked.value = true;
  orderInfo.value.guidelines = selectedGuidelines.value;
  if (!isLabResultsValid.value) {
    scrollToSection("labResults");
    return;
  }
  if (!isPatientChartNotesValid.value) {
    scrollToSection("patientChartNotes");
    return;
  }
  if (!isMedicalNecessityValid.value) {
    scrollToSection("medicalNecessity");
    return;
  }
  if (isLabResultsValid.value && isPatientChartNotesValid.value && isMedicalNecessityValid.value) {
    savePartialOrderInfo();
    emit("nextClicked");
  }
};

const addDownloadLink = (downloadLinkInfo) => {
  if (downloadLinkInfo.label) {
    const foundItem = orderInfo.value[downloadLinkInfo.storeVar].find((item) => item.label === downloadLinkInfo.label);
    if (foundItem) {
      foundItem.links.push(downloadLinkInfo.link);
    }
  } else {
    orderInfo.value[downloadLinkInfo.storeVar]
      ? orderInfo.value[downloadLinkInfo.storeVar].push(downloadLinkInfo.link)
      : (orderInfo.value[downloadLinkInfo.storeVar] = [downloadLinkInfo.link]);
  }
};

function addFreeFormSupplementalDocs(downloadLinkInfo) {
  if (!supplementalDocs.value[downloadLinkInfo.storeVarIndex]) {
    supplementalDocs.value[downloadLinkInfo.storeVarIndex] = { label: null, links: [] };
  }
  supplementalDocs.value[downloadLinkInfo.storeVarIndex].links.push(downloadLinkInfo.link);
  supplementalDocs.value[downloadLinkInfo.storeVarIndex].label = downloadLinkInfo.fileName;
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
