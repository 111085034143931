<template>
  <GreenCirclePreloader v-if="showPreloader" />
  <div class="upload-section">
    <input ref="pdfInput" type="file" accept=".pdf, .png, .jpg, .jpeg" @change="handleFileUpload" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { uuid } from "vue-uuid";
import { uploadService } from "@/services/uploadService";
import { useOrderInfoStore } from "@/stores/orderInfoStore";
import { storeToRefs } from "pinia";
import { convertDateToYYYYMMDD } from "@/utils";
import { usaStates } from "@/utils/usaStates";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";

const { orderInfo } = storeToRefs(useOrderInfoStore());

let uploadKey = null;

const showPreloader = ref(false);
const pdfInput = ref(null);
const ocrResults = ref(null);
const allowedFileTypes = ["application/pdf", "image/png", "image/jpeg"];

const handleFileUpload = async (event) => {
  showPreloader.value = true;
  const file = event.target.files[0];
  if (!file) return;
  if (!allowedFileTypes.includes(file.type)) return;
  if (file.size > 10000000) return;
  const uuidv4 = uuid.v4();
  uploadKey = uuidv4;
  const uploadOcrResults = await uploadService.getResultsFromOCR(file, uploadKey);
  const ocrResultsInitial = uploadOcrResults.response1;
  mapOcrKeyToOrderInfo(ocrResultsInitial);
  cleanUpFields();
  showPreloader.value = false;
};

const patientFieldMapping = {
  firstName: ["fullname", "first", "patientfirstname", "patientfirst", "firstname"],
  gender: ["sex", "gender"],
  dob: ["dob", "dateofbirth", "birthdate", "birthday"],
  insuranceId: [
    "memberid",
    "patientinsurancenumber",
    "patientidnumber",
    "insuranceid",
    "patientinsuranceid",
    "membernumber",
  ],
  zip: ["zipcode", "zip", "patientzipcode", "patientzip"],
  address1: [
    "address",
    "address1",
    "patientaddress",
    "patientaddress1",
    "patientstreetaddress",
    "streetaddress",
    "street",
  ],
  address2: ["address2", "patientaddress2", "patientapt", "apt", "patientapartment", "apartment"],
  phone: ["mobilephone"],
  weight: ["patientweight", "weight"],
  height: ["patientheight", "height"],
  city: ["patientcity", "city"],
  state: ["patientstate", "state"],
  lastName: ["lastname", "last", "patientlastname", "patientlast", "lastname"],
  middleName: ["middlename", "middle", "patientmiddlename", "patientmiddle", "middlename", "mi"],
  prefix: ["prefix", "patientprefix", "patienttitle"],
  suffix: ["suffix", "patientsuffix"],
  drugAllergies: ["allergies", "drugallergies", "patientallergies", "allergies"],
  ssn: ["ssn", "patientsocialsecuritynumber", "socialsecuritynumber", "socialsecurity"],
  insuranceMemberName: ["insurancemembername", "patientinsurancemembername", "membername"],
};

const providerFieldMapping = {
  npiNumber: ["npi", "nationalprovideridentifier", "provideridentifier", "providernpi"],
  providerFirstName: ["providername", "providerfullname"],
  providerLastName: ["providerlastname", "providerlast", "providername"],
  providerTitle: ["providertitle", "providerjobtitle", "providerrole", "providerposition"],
  practiceTitle: ["practicename", "providerpracticename", "providerpracticetitle", "practicetitle"],
  providerAddress1: ["practiceaddress", "provideraddress1", "providerstreetaddress", "streetaddress"],
  providerCity: ["practicecity", "providercity"],
  providerState: ["practicestate", "providerstate"],
  providerZip: ["practicezip", "providerzip"],
  providerEmail: ["email", "provideremail", "provideremailaddress"],
  contactPhone: ["phonenumber", "phone", "providerphone", "providerphonenumber"],
  contactFax: ["fax", "providerfax", "providerfaxnumber"],
  contactName: ["contactname", "providercontactname", "referralcoordinatorname"],
  officeName: [
    "practicename",
    "providerpracticename",
    "providerpracticetitle",
    "officename",
    "providerofficename",
    "practiceofficename",
    "practicetitle",
  ],
  officePhone: ["practicephonenumber", "providerofficephone", "officephonenumber"],
  fax: ["practicefax", "providerofficefax", "officefax", "providerfax"],
  tin: ["taxid", "taxidentificationnumber", "taxidnumber", "providertaxid", "provdertin"],
  deaNumber: ["deanumber", "dea", "deaidentifier", "providerdeanumber"],
  licenseNumber: ["licensenumber", "providerlicensenumber", "providerlicense"],
  specialty: ["specialty", "providerspecialty", "providerrole", "providerposition"],
  email: ["provideremail", "provideremailaddress", "email"],
};

function cleanUpFields() {
  const patientFullName = orderInfo.value.patientInfo.firstName ? orderInfo.value.patientInfo.firstName.split(" ") : [];
  if (patientFullName.length > 1) {
    orderInfo.value.patientInfo.firstName = patientFullName[0];
    orderInfo.value.patientInfo.lastName = patientFullName[1];
  }
  const providerFullName = orderInfo.value.providerInfo.providerFirstName
    ? orderInfo.value.providerInfo.providerFirstName.split(" ")
    : [];
  if (providerFullName.length > 1) {
    orderInfo.value.providerInfo.providerFirstName = providerFullName[0];
    orderInfo.value.providerInfo.providerLastName = providerFullName[1];
  }
  if (
    orderInfo.value.providerInfo.contactPhone &&
    orderInfo.value.providerInfo.contactPhone.includes("(734) 203-0176")
  ) {
    const contactPhone = orderInfo.value.providerInfo.contactPhone.replace("(734) 203-0176", "");
    orderInfo.value.providerInfo.contactPhone = contactPhone;
  }
  if (orderInfo.value.providerInfo.contactFax && orderInfo.value.providerInfo.contactFax.includes("(888) 373-5528")) {
    const contactFax = orderInfo.value.providerInfo.contactFax.replace("(888) 373-5528", "");
    orderInfo.value.providerInfo.contactFax = contactFax;
  }
  if (orderInfo.value.providerInfo.email && orderInfo.value.providerInfo.email.includes("referral@uptivhealth.com")) {
    const providerEmail = orderInfo.value.providerInfo.email.replace("referral@uptivhealth.com", "");
    orderInfo.value.providerInfo.email = providerEmail;
  }
}

function mapOcrKeyToOrderInfo(ocrResultsInitial) {
  // get keys of patientInfo from orderInfo
  const patientInfoKeys = Object.keys(orderInfo.value.patientInfo);
  patientInfoKeys.splice(patientInfoKeys.indexOf("id"), 1);
  const providerInfoKeys = Object.keys(orderInfo.value.providerInfo);
  providerInfoKeys.splice(providerInfoKeys.indexOf("id"), 1);
  ocrResults.value = Object.keys(ocrResultsInitial).reduce((acc, key) => {
    const keyLower = key.toLowerCase().replace(/\s/g, "").replace("-", "").replace(":", "");
    acc[keyLower] = ocrResultsInitial[key];
    return acc;
  }, {});
  const ocrResultsKeys = Object.keys(ocrResults.value);
  // map ocrResults to patientInfoKeys if they are the similar using fuzzy matching
  ocrResultsKeys.map((ocrKey) => {
    const patientInfoKey = patientInfoKeys.find((patientKey) => {
      const patientKeyLower = patientKey.toLowerCase();
      // replace patient with empty string, remove whitespace and colon
      const patientFieldMappingValues = patientFieldMapping[patientKey];
      if (patientFieldMappingValues) {
        return (
          patientKeyLower.includes(ocrKey) ||
          ocrKey.includes(patientKeyLower) ||
          patientFieldMappingValues.includes(ocrKey)
        );
      } else return patientKeyLower.includes(ocrKey) || ocrKey.includes(patientKeyLower);
    });
    if (patientInfoKey) {
      alignFieldFormats(patientInfoKey, ocrKey);
      orderInfo.value.patientInfo[patientInfoKey] = ocrResults.value[ocrKey] ? ocrResults.value[ocrKey] : null;
    }
    const providerInfoKey = providerInfoKeys.find((providerKey) => {
      const providerKeyLower = providerKey.toLowerCase();
      const providerFieldMappingValues = providerFieldMapping[providerKey];
      if (providerFieldMappingValues) {
        return (
          providerKeyLower.includes(ocrKey) ||
          ocrKey.includes(providerKeyLower) ||
          providerFieldMappingValues.includes(ocrKey)
        );
      } else return providerKeyLower.includes(ocrKey) || ocrKey.includes(providerKeyLower);
    });
    if (providerInfoKey) {
      alignFieldFormats(providerInfoKey, ocrKey);
      orderInfo.value.providerInfo[providerInfoKey] = ocrResults.value[ocrKey] ? ocrResults.value[ocrKey] : null;
    }
  });
}

function alignFieldFormats(patientInfoKey, ocrKey) {
  if (ocrResults.value[ocrKey] && ocrResults.value[ocrKey].length) {
    switch (patientInfoKey) {
      case "gender":
        if (ocrResults.value[ocrKey].length === 1) {
          ocrResults.value[ocrKey] = ocrResults.value[ocrKey].toUpperCase() === "M" ? "Male" : "F" ? "Female" : null;
        } else {
          const genderString = ocrResults.value[ocrKey].toLowerCase().replace(/\s/g, "");
          if (genderString.includes("[x]")) {
            const patientGender = genderString.split("[x]")[1];
            ocrResults.value[ocrKey] = patientGender[0] === "m" ? "Male" : patientGender[0] === "f" ? "Female" : null;
          }
        }
        break;
      case "dob":
        ocrResults.value[ocrKey] = convertDateToYYYYMMDD(ocrResults.value[ocrKey]);
        break;
      case "state":
        if (ocrResults.value[ocrKey].length === 2) {
          const stateAbbreviation = ocrResults.value[ocrKey];
          const stateName = Object.values(usaStates).find((state) => state.abbreviation === stateAbbreviation).name;
          ocrResults.value[ocrKey] = stateName;
        }
        break;
      default:
        break;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/components/_upload-file-component.scss";
</style>
