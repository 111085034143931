import { defineStore } from "pinia";
import { ref } from "vue";

export const useRequirementsStore = defineStore("requirements", () => {
  const requirementsData = ref({
    medicationRequirements: null,
    diagnosisRequirements: null,
    payerQuestions: null,
    stepTherapyRequirements: null,
    insurancePlanTypes: null,
  });
  return {
    requirementsData,
  };
});
