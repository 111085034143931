<template>
  <div class="patient-information-form-wrapper">
    <div class="form">
      <div>
        <div
          v-if="(props.labResults && props.labResults.length) || (props.patientChart && props.patientChart.length)"
          class="patient-information-part">
          <h5>Supporting Documents</h5>
          <hr class="bold-hr" />
          <div v-if="props.labResults && props.labResults.length" class="reverse-td-width">
            <div class="full-width-table-container">
              <table>
                <thead>
                  <tr>
                    <th>Lab Results</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="chart in props.labResults" :key="chart.label">
                    <td>
                      <span>
                        <p class="label">{{ chart.label }}</p>
                      </span>
                    </td>
                    <td>
                      <span v-if="chart.links && chart.links.length">
                        <div v-for="link in chart.links" :key="link" class="input-wrapper">
                          <div class="download">
                            <a :href="link" target="_blank">Download</a>
                          </div>
                        </div>
                      </span>
                      <span v-else>
                        <span v-if="!props.isEditable">
                          <p class="label">Not Uploaded</p>
                        </span>
                        <span v-else>
                          <UploadFileComponent
                            :download-destination="`labResults`"
                            :download-label="chart.label"
                            class="input-wrapper"
                            @download-link="addDownloadLink" />
                        </span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="props.patientChart && props.patientChart.length" class="reverse-td-width">
            <div class="full-width-table-container">
              <table>
                <thead>
                  <tr>
                    <th>Patient Chart Notes</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="chart in props.patientChart" :key="chart.label">
                    <td>
                      <span>
                        <p class="label">{{ chart.label }}</p>
                      </span>
                    </td>
                    <td>
                      <span v-if="chart.links && chart.links.length">
                        <div v-for="link in chart.links" :key="link" class="input-wrapper">
                          <div class="download">
                            <a :href="link" target="_blank">Download</a>
                          </div>
                        </div>
                      </span>
                      <span v-else>
                        <span v-if="!props.isEditable">
                          <p class="label">Not Uploaded</p>
                        </span>
                        <span v-else>
                          <UploadFileComponent
                            :download-destination="`patientChart`"
                            :download-label="chart.label"
                            class="input-wrapper"
                            @download-link="addDownloadLink" />
                        </span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="props.isEditable">
          <SupplementalDocumentsUploadComponent
            :supplemental-docs="supplementalDocs"
            @add-free-form-supplemental-docs="addFreeFormSupplementalDocsDownloadLink" />
        </div>
      </div>
      <div>
        <div id="medicalNecessity" class="patient-information-part">
          <h5>Statement of Medical Necessity</h5>
          <hr class="bold-hr" />
          <div v-if="props.medicalNecessityTemplate && props.medicalNecessityTemplate.length">
            <div class="accented-paragraph">Download Template to Get Signed By Physician</div>
            <div class="download-template">
              <a :href="props.medicalNecessityTemplate" target="_blank">Download Template</a>
            </div>
          </div>
          <div>
            <div v-if="props.medicalNecessity" class="reverse-td-width">
              <div class="full-width-table-container">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <span><p class="label">Statement of Medical Necessity</p></span>
                      </td>
                      <td>
                        <div v-for="statement in props.medicalNecessity" :key="statement" class="input-wrapper">
                          <div class="download">
                            <a :href="statement" target="_blank">Download</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else class="reverse-td-width">
              <div class="full-width-table-container">
                <table>
                  <tbody>
                    <tr>
                      <td class="input-wrapper">
                        <span><p class="label">Upload Statement Signed by Physician</p></span>
                      </td>
                      <td>
                        <div v-if="!props.isEditable">
                          <span><p class="label">Not Uploaded</p></span>
                        </div>
                        <span v-else>
                          <UploadFileComponent
                            :download-destination="`medicalNecessity`"
                            class="input-wrapper"
                            @download-link="addDownloadLink" />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="guidelines-checklist">
        <h5 v-if="props.guidelines.length">
          I want to attach the following clinical guidelines to my patient’s prior authorization
        </h5>
        <div v-for="guideline in props.guidelines" :key="guideline.label" class="checklist-item">
          <input
            :id="`${guideline.label}`"
            v-model="guidelines"
            :value="guideline"
            type="checkbox"
            :disabled="!props.isEditable" />
          <label :for="`${guideline.label}`">
            <a :href="guideline.url" target="_blank">{{ guideline.label }}</a>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import UploadFileComponent from "@/components/UploadFileComponent";
import SupplementalDocumentsUploadComponent from "@/components/SupplementalDocumentsUploadComponent";

const props = defineProps({
  labResults: {
    type: Array,
    default() {
      return [];
    },
  },
  patientChart: {
    type: Array,
    default() {
      return [];
    },
  },
  guidelines: {
    type: Array,
    default() {
      return [];
    },
  },
  medicalNecessity: {
    type: Array,
    default() {
      return [];
    },
  },
  medicalNecessityTemplate: {
    type: String,
    default() {
      return "";
    },
  },
  isEditable: {
    type: Boolean,
    default() {
      return false;
    },
  },
  supplementalDocs: {
    type: Array,
    default() {
      return [];
    },
  },
});

const supplementalDocs = ref(props.supplementalDocs);

const emit = defineEmits(["addUploadedFile", "updateGuidelines", "add-free-form-supplemental-docs"]);

const addDownloadLink = (downloadLinkInfo) => {
  emit("addUploadedFile", downloadLinkInfo);
};

const addFreeFormSupplementalDocsDownloadLink = (downloadLinkInfo) => {
  emit("add-free-form-supplemental-docs", downloadLinkInfo);
};

const guidelines = ref(props.guidelines);

watch(guidelines, (newGuidelines) => {
  emit("updateGuidelines", newGuidelines);
});
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
